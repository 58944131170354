import styled from "styled-components";

export const Line = styled.hr`
  border-top: 2px solid rgb(234 234 234);
  width: 100%;
`;

export const ContainerLine = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
`;
