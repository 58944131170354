import React from "react";

import { NextControl, PreviousControl, ArrowLeft, ArrowRight } from "./styles";

import Data from "../../data/videos";

function ControlPagination({ data, video, change, position, changePosition }) {
  const setLeft = () => {
    if (position === 0) {
      const length = Data.length;
      change(Data[length - 1].link);
      changePosition(length - 1);
      return;
    }
    change(Data[position - 1].link);
    changePosition(position - 1);
  };

  const setRight = () => {
    if (position === Data.length - 1) {
      change(Data[0].link);
      changePosition(0);
      return;
    }
    change(Data[position + 1].link);
    changePosition(position + 1);
  };
  return (
    <>
      <NextControl>
        <ArrowRight onClick={setRight}>→</ArrowRight>
      </NextControl>
      <PreviousControl>
        <ArrowLeft onClick={setLeft}>←</ArrowLeft>
      </PreviousControl>
    </>
  );
}

export default ControlPagination;
