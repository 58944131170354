const videos = [
  {
    id: "1",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667309166/manifolds/manifold%2012.mp4",
  },
  {
    id: "2",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667309343/manifolds/manifold%2013.mp4",
  },
  {
    id: "3",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667309620/manifolds/manifold%2014.mp4",
  },
  {
    id: "4",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667310406/manifolds/manifold%2015.mp4",
  },
  {
    id: "5",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667310749/manifolds/manifold%2016.mp4",
  },
  {
    id: "6",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667311075/manifolds/manifold%2017.mp4",
  },
  {
    id: "7",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667311388/manifolds/manifold%2018.mp4",
  },
  {
    id: "8",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1667312056/manifolds/manifold%2019.mp4",
  },
  {
    id: "9",
    link:
      "  https://res.cloudinary.com/arbid/video/upload/v1667312369/manifolds/manifold%2020.mp4",
  },
  {
    id: "10",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726229319/manifolds/manifold%201.mp4",
  },
  {
    id: "11",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726243051/manifolds/manifold%202.mp4",
  },
  {
    id: "12",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242963/manifolds/manifold%203.mp4",
  },
  {
    id: "13",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242898/manifolds/manifold%204.mp4",
  },
  {
    id: "14",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242785/manifolds/manifold%205.mp4",
  },
  {
    id: "15",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242705/manifolds/manifold%206.mp4",
  },
  {
    id: "16",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242633/manifolds/manifold%207.mp4",
  },
  {
    id: "17",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242522/manifolds/manifold%208.mp4",
  },
  {
    id: "18",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726242244/manifolds/manifold%209.mp4",
  },
  {
    id: "19",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726241715/manifolds/manifold%2010.mp4",
  },
  {
    id: "20",
    link:
      "https://res.cloudinary.com/arbid/video/upload/v1726241561/manifolds/manifold%2011.mp4",
  },
];

export default videos;