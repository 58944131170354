import React from "react";

import { Container, Page, Text } from "./styles";

function SliderPagination({ data, video, change, changePosition, position }) {
  const onClickPosition = (value, key) => {
    change(value);
    changePosition(key);
  };
  const rederPages = () => {
    const size = window.innerWidth;
    const landScape = window.innerWidth <= 823 && window.innerHeight <= 480;
    if (size <= 500 || landScape) {
      let pagination = (
        <Text>
          {position + 1}/{data.length}
        </Text>
      );

      return pagination;
    }

    return data.map((value, key) => (
      <Page
        onClick={() => onClickPosition(value.link, key)}
        key={key}
        active={value.link === video}
      />
    ));
  };
  return <Container>{rederPages()}</Container>;
}

export default SliderPagination;
