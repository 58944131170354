import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(234 234 234);
  color: rgb(0 0 0);
  top: 0;
  left: 0;
  padding-top: 1em;
  padding-left: 10.5em;
  z-index: 100000;
  overflow: scroll;

  cursor: pointer;

  visibility: hidden;

  @media (max-width: 1240px) {
    padding-top: 3em;
    padding-left: 2.63em;
  }
`;

export const Bar = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 50px;

  background-color: rgb(234 234 234);

  @media (max-width: 1240px) {
    display: block;
  }
`;

export const Text = styled.p`
  width: 45%;
  margin-top: 1em;
  font-size: 28px;
  line-height: 1.15em;
  letter-spacing: 0.01em;

  color: rgb(0 0 0);

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 414px) {
    width: 83% !important;
    font-size: 23px;
  }

  @media (max-width: 1240px) {
    width: 90%;
  }
`;

export const FixedImg = styled.img`
  position: fixed;
  max-width: 20%;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);

  /* visibility: hidden; */

  /* -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out; */

  @media (max-width: 400px) {
    top: 65% !important;
  }

  @media (max-width: 1240px) {
    max-width: 193px;
    width: 40%;
    top: 65%;
    bottom: 2%;
    left: 2%;
    transform: none;
  }
`;
