import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  z-index: 1000;
`;

export const Page = styled.label`
  margin: 0px 2px;
  width: 2.5vw;
  height: 2px;
  display: inline-block;
  background: ${(props) =>
    props.active ? "rgba(255, 255, 255, 1)" : "rgb(255 255 255 / 20%)"};
  margin: 0 2px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;

export const Text = styled.p`
  font-family: "EB Garamond", serif;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 500;

  cursor: pointer;

  opacity: 1;
  transition: all 0.5s;

  color: rgb(255 255 255);
`;
