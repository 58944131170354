import React, { useState } from "react";
import Video from "./components/video/index";
import SliderPagination from "./components/SliderPagination/index";
import ControlPagination from "./components/ControlPagination/index";

import Contact from "./pages/Contact/index";

import Data from "./data/videos";

function App() {
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState(Data[0].link);
  const [initialClientX, setinitialClientX] = useState(0);
  const [finalClientX, setfinalClientX] = useState(0);
  const [position, setPosition] = useState(0);
  const handleNext = () => {
    if (position === Data.length - 1) {
      setVideo(Data[0].link);
      setPosition(0);
    } else {
      setVideo(Data[position + 1].link);
      setPosition(position + 1);
    }
  };
  console.log("data - ", Data, video, position);
  const showContact = () => {
    if (show) {
      setShow(false);
      return;
    }
    setShow(true);
  };

  const handleTouchStart = (event) => {
    setinitialClientX(event.nativeEvent.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setfinalClientX(event.nativeEvent.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (finalClientX < initialClientX) {
      setRight();
    } else {
      setLeft();
    }

    setinitialClientX(0);
    setfinalClientX(0);
  };

  const setLeft = () => {
    if (position === 0) {
      const length = Data.length;
      setVideo(Data[length - 1].link);
      setPosition(length - 1);
      return;
    }
    setVideo(Data[position - 1].link);
    setPosition(position - 1);
  };

  const setRight = (x) => {
    if (position === Data.length - 1) {
      setVideo(Data[0].link);
      setPosition(0);
      return;
    }
    setVideo(Data[position + 1].link);
    setPosition(position + 1);
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <span
        onClick={showContact}
        className="logo"
        style={{ color: show ? "#000" : "#fff", opacity: 1 }}
      >
        Manifolds
      </span>
      <Contact
        active={show}
        desative={() => {
          setShow(false);
        }}
      />
      <Video handleNext={() => handleNext()} autoPlay srcMp4={video} />
      <SliderPagination
        data={Data}
        video={video}
        change={(value) => setVideo(value)}
        changePosition={(value) => setPosition(value)}
        position={position}
      />
      <ControlPagination
        data={Data}
        video={video}
        position={position}
        change={(value) => setVideo(value)}
        changePosition={(value) => setPosition(value)}
      />
    </div>
  );
}

export default App;
