import React, { useRef, useEffect } from "react";

import { ContainerLine } from "./styles";

const Video = ({ srcMp4, backgroundImage, handleNext }) => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current.play();
  }, [srcMp4]);

  return (
    <div className="video-container">
      <ContainerLine>
        <div className="loading">
          <div className="loading_line_wrapper">
            <div className="loading_line">
              <div className="loading_line_inner loading_line_inner--1"></div>
              <div className="loading_line_inner loading_line_inner--2"></div>
            </div>
          </div>
        </div>
      </ContainerLine>

      <video
        style={{ zIndex: 2, position: "absolute" }}
        width={"100%"}
        preload="auto"
        playsInline
        muted
        onEnded={() => {
          handleNext();
        }}
        src={srcMp4}
        ref={(ref) => (videoRef.current = ref)}
      ></video>
    </div>
  );
};

export default Video;
