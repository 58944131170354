import styled from "styled-components";

export const NextControl = styled.div`
  position: absolute;
  top: 0;
  width: 15%;
  height: 100%;
  z-index: 55;

  right: 0%;

  opacity: 0;
  transition: all 0.5s;

  &:hover {
    opacity: 1;
  }
`;

export const PreviousControl = styled.div`
  position: absolute;
  top: 0;
  width: 15%;
  height: 100%;
  z-index: 55;

  left: 0%;

  opacity: 0;
  transition: all 0.5s;

  &:hover {
    opacity: 1;
  }
`;

export const ArrowLeft = styled.span`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50px;
  margin-top: -25px;

  font-family: "Noto Sans JP", sans-serif;
  font-size: 30px;

  left: 1em;
  text-align: left;

  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`;

export const ArrowRight = styled.span`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50px;
  margin-top: -25px;

  font-family: "Noto Sans JP", sans-serif;
  font-size: 30px;

  right: 1em;
  text-align: right;

  color: rgba(255, 255, 255, 1);
  cursor: pointer;
`;
