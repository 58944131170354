import React, { useEffect, useRef, useState } from "react";
import { Fade } from "@material-ui/core";
import { Container, Text, FixedImg, Bar } from "./styles";
import Img1 from "../../assets/img/01.jpg";
import Img2 from "../../assets/img/02.jpg";
import Img3 from "../../assets/img/03.jpg";

function Contact(props) {
  const myRef = useRef();
  const ContainerRef = useRef();
  const [displayImage1, setDisplayImage1] = useState(false);
  const [displayImage2, setDisplayImage2] = useState(false);
  const [displayImage3, setDisplayImage3] = useState(false);

  //Execute when the component is monted
  useEffect(() => {
    ContainerRef.current.addEventListener("scroll", trackScrolling);
    // document.addEventListener("mousewheel", detectMouseWheelDirection);
    return () => {
      ContainerRef.current.removeEventListener("scroll", trackScrolling);
      // window.removeEventListener("mousewheel", detectMouseWheelDirection);
    };
  }, [myRef]);

  const trackScrolling = () => {
    const distance = ContainerRef.current.scrollTop;

    if (distance >= 300) {
      setDisplayImage1(true);
    } else {
      // We need this to make sure the image fades out on scroll up
      setDisplayImage1(false);
    }

    if (distance >= 1800) {
      setDisplayImage1(false);
    }

    if (distance >= 3800) {
      setDisplayImage2(true);
    } else {
      setDisplayImage2(false);
    }

    if (distance >= 5300) {
      setDisplayImage2(false);
    }

    if (distance >= 7300) {
      setDisplayImage3(true);
    } else {
      setDisplayImage3(false);
    }

    if (distance >= 8800) {
      setDisplayImage3(false);
    }
  };

  return (
    <Container
      ref={ContainerRef}
      className={props.active ? "show" : "hide"}
      onClick={props.desative}
    >
      <Bar />
      <Text style={{ marginTop: '-1px', fontSize: 28 }}>Franco Clivio</Text>
      <br />
      <br />
      <br />

      <Text ref={myRef}>
        In order to talk about Franco Clivio’s extraordinary, dare I say truly
        luminous art works, allow me to take you on an anecdotal stroll.
        Anecdotes are a risky way of relating to the world, based as they are
        upon the subjectivity of their narrator—indeed, they are anything but a
        neutral, straightforward laying out of the facts as, for example, any
        historical account would strive to be. Anecdotes are a mode of
        storytelling that aims to achieve something. They are a narrative form
        that not only wants to say something, it also means something. Above
        all, anecdotes are significant, symptomatic, even programmatic.
      </Text>
      <Text>
        In this case, we’re looking at Franco Clivio’s artistic practice. But it
        certainly involves me a bit, too, since I’m the one talking. And anyway,
        Franco and I have a strong connection: we were classmates at the
        Hochschule für Gestaltung in Ulm in the 1960s; he was in
        Produktgestaltung (product/industrial design), and I was in Visuelle
        Kommunikation (visual communication). And here our anecdote
        begins—slowly, in its own careful and circumspect way, insofar as the
        backstory is that I was completely in the dark. And so I get this phone
        call from Franco, whom I hadn’t spoken to in quite a while, at least
        since his exhibition No Name Design (2014), which I had seen at the
        Triennale di Milano and thoroughly enjoyed. With a somewhat mysterious
        air, Franco invites me to meet him at Bruno Monguzzi and Anna Boggeri’s
        place in Meride, in the Swiss canton of Ticino. He says no more, but I
        sense something promising in the invitation. Promising but also
        perturbing—so perturbing that, the next day, I get on a train to
        Chiasso, where Franco is set to meet me and drive me up to Meride, but…
        I got the day wrong! I was so enthused that I left too early.
      </Text>
      <Text>
        And so I head back to Milan and a few days later—on the right day—I head
        out again. And Franco’s at the station in Chiasso to give me a warm,
        amused welcome. We drive up into town and enjoy a meal at Monguzzi’s,
        but we don’t end up talking about what I expect. Then, finally, we all
        walk into a large, white room with a bunch of large dressers in the
        middle grouped into one huge tabletop. And this becomes the terrain upon
        which the real magic begins to unfold. With emblematic, highly aware
        gestures, Franco takes some objects out of some color-coded, custom-made
        folders. When he sets them on the table, they lie flat, but then they
        start expanding, as if being blown up. They’re wiry structures made up
        of straight lines at set angles that, left alone, attack and in a
        certain sense devour the surrounding space—albeit not without a certain
        graciousness. I said they looked as if being blown up, but I guess it’d
        be more accurate to say they spread out.
      </Text>
      <Text>
        But then saying that they’re objects is also both right and wrong. They
        are something we could go out on a limb to call geometric insects,
        because when you touch them they seem alive, and vibrate like insects.
        They are made of ultra-thin, golden metal tubes, in lengths standardized
        for every single insect-object. And the show begins, first with a
        procession of the smaller “pieces” and then, little by little, they
        increase in scale. Furthermore, this potentially infinite spatial
        expansion applies not only to the whole body of work—meaning the
        genealogy of the objects—but also to every single object taken on its
        own. Essentially, the rectilinear segments play the hidden role, so to
        speak, of hinges. Hence the elements they act in solidarity with are
        able to spin, assuming millions and millions of different positions.
      </Text>
      <Text>
        And our hands quickly learn to push these thin, rigid rods away from one
        another and pull them together again, producing—thanks to their
        structural characteristics, depending upon the length of the rods, the
        degree of their angles, etc.—unexpected metamorphic effects. But while
        it’s easy to learn how to move them, learning to control their
        metamorphoses isn’t so easy: they’re always a total surprise. Unexpected
        expansions, unforeseeable contractions. Almost as if they’re breathing,
        panting. Conversely, as you move them, you’re tempted to hold your
        breath.
      </Text>
      <Text>
        These objects are, then, interactive works of art that, as I said, shift
        shape as they come into contact with the body of what is no longer a
        passive viewer, but rather—pardon the neologistic pun—an interactor.
        That is, an actor who interacts. Ultimately, we’re talking about bodies
        (our own) functionally coupling with these objects—which, in truth, are
        less objects than they are quasi-subjects, insofar as each possesses its
        own individuality and resilience. And our bodies dance with theirs. The
        human body (through its ﬁngers) and the technical-geometric body (though
        its unique form) lace and move together, precisely as if dancing tango:
        as one advances, the other recedes or expands. It’s a to and fro full of
        potential that can immediately become real. They are, therefore,
        interactive works of art, and for me—since I took part in the kinetic
        art movement back in the 1960s (which was essentially
        interactive)—seeing them now is a little like meeting an impressive
        cousin I never knew I had.
      </Text>
      <Text>
        And here, anecdote within the anecdote, Bruno’s going to tell me: “You
        know when you saw the ﬁrst Manifolds you went pale.” It’s true, I went
        pale, such was the powerful impact of the sophisticated aesthetic
        experience I’d had—and really recognized. But what propulsive forces are
        at work in Franco’s multitasking mind? First and foremost, a compelling
        epistemological impetus: his awareness moves about in search of
        originality. Then, a powerful visionary ability that expands as it
        proceeds, taking in and embracing all new content and conﬁgurations. And
        then a refined sense of technology, that always pushes past its own
        limitations. And then, finally, a solid capacity for decision making and
        selection, intertwined with yet another necessary, complementary
        ability—that of foresight. Foreseeing means being able to envision with
        your mind’s eye, and prefigure the outcome you’re heading toward. In
        other words, it’s a matter of being certain you’ll be able to attain the
        hoped-for effect. But the reins lie firmly in Franco’s skillful hands,
        and he knows how to deploy the possibilities of both technology and
        technique, as well as imagination, in the service of aesthetic effect.
      </Text>
      <Text>
        To say that Franco Clivio is chock-full of talent might be a bit
        heretical—at least for the two of us, alumni of the Ulm school of
        design, where we learned to conceive of design as a rational process
        with a concrete aim, basically as a sequential, progressive development.
        Above all, we’ve always thought of design as an impersonal act that
        obstinately shuns subjectivism’s flattery. But the intervening years
        have also taught me that, although standardized methodologies and
        universal procedures may well be necessary foundations for staying
        rooted and stable, they mustn’t be allowed to get in the way of talent’s
        personal flair, when it’s there. I’ve wavered back and forth between art
        and design here, so at this point I’d like to point out that, over time,
        I’ve grown increasingly convinced that these two disciplines have one
        key skill in common. Even if art is generally considered autonomous
        (l’art pour l’art), and design is essentially heteronymous (meaning its
        scope lies outside itself). This generative skill shared by both art and
        design carries a prestigious name in German: Gestaltung. Gestaltung
        means to give form to objects while also considering how the design will
        be seen/used by its end perceiver/user. In short, Gestaltung deals with
        how a design will end up appearing. “Appearing,” in the sense that
        Gestalt also means “apparition.” And this is worth commenting on because
        until quite recently people often insisted (in Italian design discourse,
        at least) that Gestaltung was untranslatable. And, indeed, for many
        years that was true. Fortunately, a term that seems capable of capturing
        the same semantic functions of the German word is now gaining traction
        in Italian: configurazione, or “configuration.”
      </Text>
      <Text>
        Now, configuration is particularly fitting for the structures we’re
        talking about here, because it also captures another semantic nuance of
        Gestalt, which is the notion of “figure.” Once they’re put into play
        through manipulation and interaction, the Manifolds can also generate
        infinite pointy, vibrating, blossoming “figures.”
      </Text>
      <Text>
        But these works of art and their appearance, in reality and in the
        present day, also exhibit, in their lightness and virtual immateriality,
        a strict adherence to the contemporary moment. It really seems as if
        they couldn’t have been made in any other time but ours. Only now can
        they emerge from the ether. I’m tempted to add that their temporal
        presence has an ephemeral quality, as if it really did encompass only
        l’espace d’un matin. Basically, they’re here and now, at a set place in
        present, current space-time. A point—one that isn’t just a moment of
        chronological time, but that clearly also describes and inhabits our
        present-day period in history. And the current cultural phase,
        developing from and in the world we live in, is layered and profoundly
        mutable, if not downright unstable. It’s in continuous transformation,
        in metamorphosis.
      </Text>
      <Text>
        In connection with all of the above (albeit in its own separate, highly
        distinguishable way), Franco Clivio has built quite a career as a
        practical, intensely hands-on designer. A good example is his
        over-35-year collaboration with the company Gardena, for which he
        designed one of the most complete and intricate garden irrigation
        systems never built. Then there’s his 25+ year collaboration with Lamy,
        for which he designed the Pico, a collapsible pen that closes up small
        to fit in your pocket and extends when opened for writing. He’s also had
        a long, close collaboration with Erco’s entire production and management
        teams, creating famous designs like the Stella track-lighting system.
        And then there’s Siemens, for which he designed a series of home phones.
        And Rodenstock, for which he designed, among many other things, an
        exemplary display for eyeglasses. These and many of his other product
        designs are in countless public collections and international museums,
        from MoMA in New York to Die Neue Sammlung in Munich. But Franco studied
        under Tomás Maldonado and remains a staunch Maldonadiano to this day,
        hence he has also had a long career as a teacher and proponent of design
        culture. While still studying in Ulm he worked as an assistant to Gui
        Bonsiepe, and then went on to teach abroad (in the usa, Finland,
        Germany, and Italy). His most important and influential teaching role,
        however, came as chair of the product design program at the
        Kunstgewerbeschule Zurich. Chatting with him about his work (practical,
        artistic, and pedagogical), you’re apt to hear expressions and concepts
        that aren’t usually brought up in design discourse—like, for example,
        the crucial term “movement design.” Franco has always been fascinated by
        the geometry of foldable objects, even before encountering Bruno Munari
        and his work. Geometries and their kinetics have long held his interest.
        When he was sixteen, he constructed rotating cardboard dice with a small
        electric motor so he could study the forms generated by rotation.
      </Text>
      <Text>
        Franco always tells of how, back in Ulm in 1964, Walter Zeischegg—who,
        alongside Otl Aicher, was cofounder of the school—told him he’d created
        a cube that “could collapse flat.” But he wouldn’t show it to him! And
        so Franco rises to the challenge and, a week later, brings him the
        results of his own research and drafts. And Zeischegg was agape because
        Franco’s “soft cube” was the same as his. But Franco’s audacious and
        systematic mind had gone on beyond the basic cube to formulate an entire
        series of variations, which he presented his design teacher, all
        centered on deconstructing/collapsing various platonic solids. These
        interests of his pop in and out, surfacing every now and then before
        retreating again, until the 1970s. Only in the 1990s did Franco return
        to them, still “for fun”—his words—and by that I mean: “Working like a
        dilettante, which means searching in the fog and finding a functional
        principle. Only then can the real designing begin.” And, further, that
        when it comes to aesthetic aspects and form, you’re free, whereas you’re
        absolutely, rigidly bound to respect function.
      </Text>
      <Text>
        This was the intellectual atmosphere—a systematic approach that was
        rigorous yet at the same time gentle, flexible, and adaptable—in which
        the pedagogical, theory-loving spirit of Tomás Maldonado hovers over
        everything. For Franco, Maldonado is a key figure—and the respect is
        mutual when you consider that Maldonado wrote the afterword to his book
        Hidden Forms, which documents the private collection of mainly
        small-scale utilitarian objects that Franco has built over a lifetime.
        It was also the inspiration behind No Name Design, a traveling
        exhibition that circled the globe and which, as I mentioned earlier, I
        saw at the Triennale di Milano.
      </Text>
      <Text>
        But as we conjure up the foundations upon which Franco Clivio built his
        skills as a surveyor of space, we cannot leave out Helmut Emde, one of
        the more highly specialized instructors at Ulm. He was a consummate
        professor who taught descriptive geometry with a disturbing expository
        brilliance and absolute persuasive immediacy. Both Franco and I remember
        Prof. Emde working out explanations whose numerical, algebraic rigor was
        absorbed by and then expressed in his striking gestural performances at
        the blackboard.
      </Text>
      <Text>
        In any case, that skill we referred to as “movement design”—which
        consists of establishing the paths, orbits, spirals, and transformations
        of the objects in question—is something that has grown ever more central
        in Franco’s work as his Manifolds grow increasingly interactive. As
        Franco tells it: “Rodolfo Bonetto, who was teaching at Ulm, had assigned
        an exercise involving a spherical alarm clock. Hearing this, Walter
        Zeischegg (who in addition to his immense geometric skill also had a
        prickly personality) yelled out: ‘These kids don’t even know how to make
        a circle stand up! Or how to make it stay put!’ It was this
        spherical-object design competition,” Franco continues, “this assignment
        that both afﬁrmed and at the same time negated movement… it was this
        challenge that really struck me to the core. And I should add that this
        wasn’t an exercise I did as a mere school assignment. It sprung from my
        own curiosity. And that’s when I developed the clock whose display angle
        could be changed by just rotating the upper ring so the user could read
        it more easily.”
      </Text>
      <Text>
        There’s another thing we mustn’t fail to mention when it comes to
        metamorphic and kinetic design: the fact that Franco invariably brings
        up Bruno Munari. In Ulm, Munari wasn’t widely respected. He was
        considered too light. And anyone who’d written a book titled Arte come
        mestiere (“Art as Craft,” published in English translation as Design as
        Art) couldn’t have gone over well with Ulm’s more orthodox adherents,
        who were fanatical functionalists and anti-art ideologues. But Franco
        considers Munari a key milestone along the path that led him to make the
        Manifolds. He remembers him as a great folding expert, invoking as proof
        that “most Japanese” skill of “super-Japanese” Munari: origami. And it
        makes sense that he titled this body of work in English, since it
        contains the word fold… One highly important detail we haven’t yet
        touched upon is color: for any aesthetic object, the question of
        chromatic choice is obviously quite relevant. An object appears, but it
        doesn’t appear in black and white, it appears in color: meaning it can
        shine in a polychrome magnificence all its own. We know that for the
        Manifolds, the choice of color was a crucial step, involving a lot of
        different drafts. Naturally, for an old-school Ulm-School designer like
        Franco, the first option had to be grey. In Ulm the philosophy or,
        better yet, the chromatic policy, was a variation on Henry Ford’s edict
        regarding the Model T: “They can be any color as long as it’s black.” In
        Ulm products could be any color as long as it was grey. Paradoxically,
        even the color of reﬂective surfaces had to be not white, but a color
        known as graue Leuchtfarbe (“luminous grey”)! This principle of using
        neutral grey was adopted alongside another, complementary principle of
        staying true to material à la Adolf Loos.
      </Text>
      <Text>
        Indeed, Franco’s first iterations were in stainless steel—meaning
        metallic grey—but they didn’t have sufficient contrast. And so he tried
        black: still too little contrast. Then, as he readily confesses with
        refreshing honesty, his photographer friend Hans Hansen suggested he
        make them gold, as it’s a clearly metallic color. And in the end, they
        became ultra-thin, super-hard, stainless steel tubes plated in gold. The
        advantage is that gold is the only material that doesn’t oxidize, so its
        color doesn’t change. So much for the methodological bigotry of truth to
        material! As we’ve seen, after a lifelong practice following a
        heteronymous, functionalist, highly practical design aesthetic, Franco
        Clivio has opted to change tack and try a more autonomous route. In
        other words, he’s chosen to focus not only the eye of his sensory body,
        but also his mind’s eye, on the appearance his objects gradually assume.
        Or, better yet, Franco Clivio has focused his attention on the
        morphological results determined by the process of working on said
        objects in the course of designing and making them. Clearly, the
        conceptual rigor and morphological precision that have always
        characterized his pragmatic design work have now usefully migrated into
        his artistic Gestaltung. But at a certain point the Manifolds took off.
        It’s as if they took flight and became entirely autonomous, thereby
        achieving the substantial and discriminating aspect of the artistic. The
        fact that their foundation and final purpose is found within and only
        within has made them what they are.
      </Text>
      <Text>
        Now the Manifolds can move, stand in any position, and change shape
        based on any of the many configurations in which they begin. In
        particular, when they assume a specific position, if we look at them
        intensely we can see countless potential variations in scale, size,
        form, and figure. Their linear segments circumscribe a series of planes,
        which in turn hint at volumes. The structure goes from two-dimensional
        to three-dimensional, and at this point our mind’s eye manages to see
        these volumes. Right there, in front of us, with an almost brazen
        presence. Our body, then—already activated by the passive act of seeing,
        and even moreso by the interventionist act of gesturing—finds itself
        playing an interactive game. Through these structures, it has invented
        unstructured play. And now we know: these Manifolds are art.
      </Text>
      <Text>Giovanni Anceschi</Text>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <span className="mail">
        <a href="mailto:fclivio@bluewin.ch">Contact</a>
      </span>

      <Fade in={displayImage1} timeout={1000}>
        <FixedImg src={Img1} />
      </Fade>
      <Fade in={displayImage2} timeout={1000}>
        <FixedImg src={Img2} />
      </Fade>
      <Fade in={displayImage3} timeout={1000}>
        <FixedImg src={Img3} />
      </Fade>
    </Container>
  );
}

export default Contact;
